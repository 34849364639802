<template>
	<div>
		<div v-if="div_show" class="donghua" @click="playDh">
			<img src="../assets/dhbg.png" style="width: 100%;height: 100%;" alt="">
			<img class="dh1" :class="show ? 'dh1ac' :''" src="../assets/dh-1.png"
				style="width: 426px;height: 46px;position: absolute;z-index: 100;" alt="">
			<img class="dh2" :class="show ? 'dh2ac' :''" src="../assets/dh-2.png"
				style="width: 357px;height: 106px;position: absolute;z-index: 100;" alt="">
			<img class="dh3" :class="show ? 'dh3ac' :''" src="../assets/dh-l.png"
				style="width: 962px;height: 622px;position: absolute;z-index: 99;" alt="">
			<img class="dh4" :class="show ? 'dh4ac' :''" src="../assets/dh-r.png"
				style="width: 962px;height: 622px;position: absolute;z-index: 99;" alt="">
		</div>
		<template v-else>
			<div class="top">
				<img src="../assets/bgtop.png" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;"
					alt="">
				<div class="top-nav">
					<!-- <img src="../assets/leyou.png" style="width: 163px;height: 58px;" alt=""> -->
					<img src="../assets/dh-2.png" style="width: 163px;" alt="">
					<div style="display: flex;margin-left: 240px;">
						<div @click="change(k)" class="item " :class="current == k ? 'itemac' :''" v-for="(v,k) in nav"
							:key="k">{{v}}</div>
					</div>
					<img src="../assets/mobile.png" style="width: 18px;height: 18px;margin-left: 40px;" alt="">
					<span style="color: #fff;font-size: 16px;margin-left: 20px;">400-822-0776</span>
				</div>
				<transition appear name="animate__animated animate__bounce" enter-active-class="animate__slideInUp"
					leave-active-class="animate__zoomOut" :duration="1000">
					<img src="../assets/bannerzi.png"
						style="width: 457px;height: 174px;position: absolute;left: 20%;top: 40%;" alt="">
				</transition>
			</div>
			<div class="men">
				<div @click="menChange(0)" class="men-item" :class="men_current == 0 ? 'men-ac' :''">
					<img src="../assets/nav1.png" style="width: 26px;height: 60px;" alt="">
					<span>厨卫门</span>
					<div class="bg" :class="men_current == 0 ? 'bgdh' :''"></div>
				</div>
				<div @click="menChange(1)" class="men-item" :class="men_current == 1 ? 'men-ac' :''">
					<img src="../assets/woshimen.png" style="width: 26px;height: 60px;" alt="">
					<span>推拉门</span>
					<div class="bg" :class="men_current == 1 ? 'bgdh' :''"></div>
				</div>
				<div @click="menChange(2)" class="men-item" :class="men_current == 2 ? 'men-ac' :''">
					<img src="../assets/xitong.png" style="width: 26px;height: 60px;" alt="">
					<span>系统窗/阳光房</span>
					<div class="bg" :class="men_current == 2 ? 'bgdh' :''"></div>
				</div>
				<div @click="menChange(3)" class="men-item" :class="men_current == 3 ? 'men-ac' :''">
					<img src="../assets/woshimen.png" style="width: 26px;height: 60px;" alt="">
					<span>淋浴房</span>
					<div class="bg" :class="men_current == 3 ? 'bgdh' :''"></div>
				</div>
			</div>
			<div class="" style="font-size: 32px;text-align: center;width: 100%;margin-top: 70px;">
				你家门窗还好吗
			</div>
			<div class="" style="text-align: center;color: #999;margin-top: 20px;">
				门窗是家的守护者
			</div>
			<div class="" style="text-align: center;color: #999;margin-top: 10px;">
				有好的门窗，采用真正的避风港
			</div>
			<div style="width: 1200px;margin: 0 auto;display: flex;justify-content: space-between;margin-top: 80px;">
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<img src="../assets/t1.png" style="width: 100px;height: 100px;" alt="">
					<span style="font-size: 13px;margin-top: 16px;">型材单薄</span>
				</div>

				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<img src="../assets/t2.png" style="width: 100px;height: 100px;" alt="">
					<span style="font-size: 13px;margin-top: 16px;">密封性差</span>
				</div>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<img src="../assets/t3.png" style="width: 100px;height: 100px;" alt="">
					<span style="font-size: 13px;margin-top: 16px;">隔音不佳</span>
				</div>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<img src="../assets/t4.png" style="width: 100px;height: 100px;" alt="">
					<span style="font-size: 13px;margin-top: 16px;">老旧破损</span>
				</div>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<img src="../assets/t5.png" style="width: 100px;height: 100px;" alt="">
					<span style="font-size: 13px;margin-top: 16px;">保温效能低</span>
				</div>
			</div>
			<div class="" style="text-align: center;color: #999;margin-top: 20px;margin-top: 60px;">
				北美梧桐门窗平衡外观与性能的极致表现
			</div>
			<div class="" style="text-align: center;color: #999;margin-top: 10px;">
				从材质到设计，从外观到内在，极限、极致、极完美
			</div>
			
			<div style="width: 100%;height: 660;margin-top: 80px;">
				<div class="swiper" style="height: 100%;width: 100%;">
					<div class="swiper-wrapper" style="height: 660;">
						<div class="swiper-slide" style="width: 1080px;height: 660;" v-for="(img,index) in bannerList"
							:key="index">
							<img style="width: 1080px;height: 660;" :src="img" />
						</div>
					</div>

				</div>
			</div>

			<div style="width: 1400px;margin: 50px auto;" class="chanpinzhongxin listshow1">
				<div style="margin-bottom: 20px;">
					<img src="../assets/bt1.png" style="width: 120px;height: 55px;" alt="">
				</div>
				<div style="display: flex;">
					<img src="../assets/1.png" style="width: 700px;height: 478px;" alt="">
					<div>
						<div>
							<img src="../assets/2.png" style="width: 350px;height: 239px;" alt="">
							<img src="../assets/3.png" style="width: 350px;height: 239px;" alt="">
						</div>
						<img src="../assets/4.png" style="width: 700px;height: 239px;margin-top: -5px;" alt="">
					</div>
				</div>
				<div style="margin-top: -5px;">
					<img src="../assets/5.png" style="width: 466px;height: 248px;" alt="">
					<img src="../assets/6.png" style="width: 466px;height: 248px;" alt="">
					<img src="../assets/7.png" style="width: 466px;height: 248px;" alt="">
				</div>
			</div>

			<div style="width: 1400px;margin: 40px auto;">
				<div style="margin-bottom: 20px;">
					<img src="../assets/bt1.png" style="width: 120px;height: 55px;" alt="">
				</div>
				<div style="display: flex;">
					<img src="../assets/zhutui.png" style="width: 808px;height: 606px;" alt="">
					<div style="margin-left: 80px;flex-grow: 2;">
						<div style="font-size: 18px;color: #999;margin-bottom: 10px;">产品型号</div>
						<div
							style="font-size: 18px;color: #999;border-bottom: 1px solid #999;width: 100%;padding-bottom: 30px;">
							LY-102</div>
						<div style="font-size: 30px;font-weight: bold;margin-top: 40px;margin-bottom: 40px;">
							北美梧桐系统门窗
						</div>
						<div style="font-size: 16px;color: #999;">颜色: 混油白</div>
						<div style="font-size: 16px;color: #999;margin-top: 10px;">玻璃: 超白烟雨</div>
						<div style="font-size: 18px;margin-top: 80px;margin-bottom: 10px;">铝材</div>
						<div style="font-size: 15px;">喷漆工艺 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 静电粉末喷涂</div>
					</div>

				</div>
			</div>
			<div style="display: flex; justify-content: center;">
				<div
					@click="see"
					style="cursor:pointer;width: 445px;height: 56px;padding: 0 20px;border:1px solid #707070;background-color: transparent;margin-left: 20px;text-align: center;line-height: 56px;">
					查看更多商品
				</div>
			</div>
			<div style="width: 1400px;margin: 40px auto;" class="listshow2">
				<div style="margin-bottom: 20px;">
					<img src="../assets/bt2.png" style="height: 55px;" alt="">
				</div>
				<div style="width: 1400px;overflow: hidden;height: 536px;display: flex;">
					<div @click="txChange(0)" class="tx" :class="tx_current == 0 ? 'tx-ac':''"
						style="position: relative;">
						<img src="../assets/tx1.png" style="position: absolute;left: 0;top:0;width: 700px;height: 100%;"
							alt="">
						<div class="mask" v-if="tx_current != 0"></div>
						<div style="position: absolute;z-index: 3;left: 20px;bottom:50px;">
							<div style="color: #fff;font-size: 18px;">在满足使用性能要求的前提下</div>
							<div style="color: #fff;font-size: 14px;margin-top: 10px;">将边框的窄做到极致</div>
						</div>
					</div>
					<div @click="txChange(1)" class="tx" :class="tx_current == 1 ? 'tx-ac':''"
						style="position: relative;margin: 0 6px;">
						<img src="../assets/tx2.png" style="position: absolute;left: 0;top:0;width: 700px;height: 100%;"
							alt="">
						<div class="mask" v-if="tx_current != 1"></div>
						<div style="position: absolute;z-index: 3;left: 20px;bottom:50px;">
							<div style="color: #fff;font-size: 18px;">少即是多</div>
							<div style="color: #fff;font-size: 14px;margin-top: 10px;">突破传统技术壁垒</div>
						</div>
					</div>
					<div @click="txChange(2)" class="tx" :class="tx_current == 2 ? 'tx-ac':''"
						style="position: relative;margin-right: 6px;">
						<img src="../assets/tx3.png" style="position: absolute;left: 0;top:0;width: 700px;height: 100%;"
							alt="">
						<div class="mask" v-if="tx_current != 2"></div>
						<div style="position: absolute;z-index: 3;left: 20px;bottom:50px;">
							<div style="color: #fff;font-size: 18px;">一面极简 一面性能</div>
							<div style="color: #fff;font-size: 14px;margin-top: 10px;">全场景布局 极简新视界</div>
						</div>
					</div>
					<div @click="txChange(3)" class="tx" :class="tx_current == 3 ? 'tx-ac':''"
						style="position: relative;">
						<img src="../assets/tx4.png" style="position: absolute;left: 0;top:0;width: 700px;height: 100%;"
							alt="">
						<div class="mask" v-if="tx_current != 3"></div>
						<div style="position: absolute;z-index: 3;left: 20px;bottom:50px;">
							<div style="color: #fff;font-size: 18px;">中国风美到骨子</div>
							<div style="color: #fff;font-size: 14px;margin-top: 10px;">方寸之间皆优雅</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 1400px;margin: 40px auto;" >
				<div style="margin-bottom: 20px;">
					<!-- <img src="../assets/bt3.png" style="width: 120px;height: 55px;" alt=""> -->
					<img src="../assets/bt3.png" style="width: 175px;height: 56px;" alt="">
				</div>

			</div>
			<div class="listshow3">
				<div class="company-about">
					<div class="img"><img style="width: 100%;" src="../assets/dibu.png" alt=""></div>
					<div class="about">
						<p>北美梧桐门窗是一家集铝合金型材、门窗产品研发、工艺玻璃、钢化玻璃生产与销售为一体的全供应链综合性大型门窗企业，得益于对门窗行业配套战略资源独具优势的供应链管理优化，以及对高品质门窗产品的精益化制造，北美梧桐门窗多年来获得快速的发展。</p>
						<br />
						<p>北美梧桐门窗总部位于广东省佛山市，现拥有佛山、山东、湖北、河南、四川、五大现代化的生产基地，总占地540亩，为更好的服务客户北美梧桐门窗在河南、山东、陕西、安徽、长沙、浙江和广东设立了七大运营和物流中心，建设了全国性的服务网络，公司现拥有门窗成品生产线10条、钢化玻璃生产线4条；工艺玻璃生产线2条；快速天然气熔铸进口生产线8台；各式挤压加工生产线55条；表面氧化着色电泳及金生产线8条；宝马、瓷泳生产线2条；立式粉末喷涂生产线2条；氟碳喷涂生产线2条；断桥隔热生产线4条；木纹生产线20条；公司年产铝合金型材10万余吨，门窗、阳光房等产品年产100余万方，公司具有强大的新品开发能力，能为客户提供从产品开发、模具设计制造、型材挤压、钢化玻璃制造、技术检测、成品销售等一条龙服务。</p>
						<br />
						<p>北美梧桐门窗全面引入精细化的生产管理体系，紧抓市场趋势，以高于国际和行业标准的严苛要求，强化技术与产品，完美地诠释了严谨与创新的企业精神， 公司的智能化门窗、极简类产品深受广大消费者喜爱，成为了门窗消费的首选，北美梧桐门窗精益求精引领着人们对高品质生活的追求；凭借十余载深耕行业的技术积淀，公司提出了智能化生产+互联网+区域经销商+城市合伙人，“产、销、装”合作模式，公司秉承着“合作共赢，经营=做人”的经营理念，全力打造门窗时代的超级工厂，全力开启铝制家装门窗时代新纪元。</p>
					</div>
				</div>
				<img style="width: 100%;" src="../assets/about.jpg" alt="">
			</div>
			<div style="width: 1400px;margin: 40px auto;" >
				<div style="margin-bottom: 20px;">
					<img src="../assets/bt4.png" style="width: 342px;height: 66px;" alt="">
				</div>
				<div style="display: flex; justify-content: space-between;">
					<img src="../assets/q1.png" style="width: 660px;height: 500px;" alt="">
					<img src="../assets/q2.png" style="width: 660px;height: 500px;" alt="">
				</div>
			</div>
			<img style="width: 100%;" src="../assets/mchx-banner.png" alt=""  class="listshow4">
			<div  class="listshow5" style="text-align: center;font-size: 28px; font-weight: bold;margin-top: 40px;">
				加盟合作 无忧帮扶
			</div>
			<div style="text-align: center;font-size: 14px; color: #999;margin-top: 10px;">
				6大帮扶措施，上手无忧
			</div>
			<img src="../assets/liucheng.png" style="width: 1400px;height: 176px;display: block;margin: 100px auto;"
				alt="">
			<div style="width: 1400px;margin: 0 auto;height: 300px;position: relative;">
				<img src="../assets/jianqu.png"
					style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 1;" alt="">
				<div style="position: relative;z-index: 2;padding-left: 45px;padding-top: 60px;">
					<div class="" style="margin-bottom: 30px;">
						<!-- 北美梧桐木门加盟合作 -->
					</div>
					<div>
						<input style="width: 445px;height: 56px;padding: 0 20px;border:none;background-color: #fff;"
							type="text" v-model="name" placeholder="*您的姓名">
						<input
							style="width: 445px;height: 56px;padding: 0 20px;border:none;background-color: #fff;margin-left: 20px;"
							type="text" v-model="mobile" placeholder="*您的号码">
					</div>
					<div style="display: flex;margin-top: 20px;">
						<input style="width: 445px;height: 56px;padding: 0 20px;border:none;background-color: #fff;"
							type="text" v-model="city" placeholder="*加盟城市">
						<div
							@click="send"
							style="cursor:pointer;width: 445px;height: 56px;padding: 0 20px;border:1px solid #707070;background-color: transparent;margin-left: 20px;text-align: center;line-height: 56px;">
							提交信息等待回复
						</div>
					</div>
				</div>
			</div>

			<div class="flex company">
				<dl class="flex-item production-base">
					<dt>全国线下生产基地及运营中心</dt>
					<dd>广东省佛山市狮山镇狮西科技工业园(总部)</dd>
					<dd>河南省新乡市封丘县黄陵镇起重工业园</dd>
					<dd>湖北省黄石市大冶市城西北工业园</dd>
					<dd>山东省临沂市兰山区西城智能家居科创园</dd>
					<dd>湖南省长沙市长沙县榔梨镇鑫创物流园</dd>
					<dd>四川省广汉市三星堆镇</dd>
				</dl>
				<dl class="flex-item contact">
					<dt>联系方式</dt>
					<dd>联系人：孙经理</dd>
					<dd>Tel：158 1566 8198</dd>
				</dl>
			</div>

			<!-- <div style="width: 1400px;height: 500px;margin: 80px auto 0;">
				<iframe style="width: 100%;height: 100%;" src="https://map.baidu.com" frameborder="0"></iframe>
			</div> -->

			<div style="height: 238px;background-color: #222222;margin-top: 120px;padding-top: 70px;">
				<div style="width: 1400px;margin: 0 auto;display: flex;align-items: center;">
					<img src="../assets/dibu.png" style="width: 200px;height: 71px;" alt="">
					<div style="width: 1px;height: 90px;background-color: #707070;margin-left: 100px;">

					</div>
					<!-- <div style="color: #999999;margin-left: 70px;">
						<div>联系人：孙经理</div>
						<div style="margin-top: 20px;margin-bottom: 20px;">联系电话：158 1566 81982</div>
						<div>门店地址：河南省新乡市封丘县黄陵镇起重工业园</div>
					</div> -->
					<div style="color: #999999;margin-left: 70px;">
						<div style="margin-bottom: 15px;">400-822-0776</div>
						<div>广东 • 佛山</div>
					</div>
				</div>
				<div style="text-align: center;color: #fff;margin-top: 50px;">
					<a href="https://beian.miit.gov.cn/" target="_blank" style="text-align: center;color: #fff;">粤ICP备2024323594号-1</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502003763" target="_blank" style="text-align: center;color: #fff;">粤公网安备44060502003763号</a>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import '../assets/swiper-bundle.js'
	import 'animate.css'
	import img1 from '../assets/1banner.png'
	import img2 from '../assets/2banner.png'
	import img3 from '../assets/3banner.png'
	export default {
		name: '首页',
		metaInfo: {
			title:'首页',
			meta: [
				{
					name:'keyWords',
					content:'门窗，北美梧桐门窗，北美梧桐，系统门窗，定制门窗，全屋门窗'
				},{
					name:'description',
					content:'北美梧桐门窗是一家集铝合金型材、门窗产品研发、工艺玻璃、钢化玻璃生产与销售为一体的全供应链综合性大型门窗企业'
				}
			]
		},
		data() {
			return {
				nav: ['首页', '产品中心', '家装实例', '关于北美梧桐', '测量设计', '加盟合作'],
				current: 0,
				men_current: 0,
				show: false,
				div_show: true,
				bannerList: [img1, img2,img3, ],
				tx_current: 0,
				showIndex:null,
				name:'',
				mobile:'',
				city:''
			}
		},
		created() {
			document.title = '北美梧桐门窗'
		},
		mounted() {
			
		},
		methods: {
			see(){
				if(window.location.pathname.includes('index/')){
					window.location.href = `${window.location.origin}/list`;
				} else {
					this.$router.push('list');
				}
			},
			send(){
				if(!this.name) return alert('请填写姓名!')
				if(!this.mobile) return alert('请填写手机号!')
				if(!this.city) return alert('请填写城市!')
				this.$axios.post('/api/pc_form/submit',{name:this.name,phone:this.mobile,city:this.city}).then(res=>{
					alert('提交成功!')
				})
			},
			 showDetails(num){
			            var el=document.getElementsByClassName(`listshow${num}`)[0];
			            console.log(el.offsetTop);
			            if(this.showIndex==num){
			                this.showIndex=null;
			            }else{
			                this.showIndex=num;
			            }
			            this.$nextTick(function () {
			                window.scrollTo({"behavior":"smooth","top":el.offsetTop});
			            })
			        },

			scrollToBottom() {
				// this.$nextTick(() => {
				// 	let box = this.$el.querySelector('.chanpinzhongxin')
				// 	GoodList.scrollTop = 40 //滚动到距离元素顶部 40px
				// })
			},
			txChange(e) {
				this.tx_current = e
			},
			change(e) {
				this.current = e
				// if(e == 1 ){
				// 	this.scrollToBottom()
				// }
				this.showDetails(e)
			},
			menChange(e) {
				this.men_current = e
			},
			playDh() {
				this.show = true
				setTimeout(() => {
					this.div_show = false
					setTimeout(()=>{
						new Swiper(".swiper", {
						
							slidesPerView: "auto",
							spaceBetween: 30,
							centeredSlides: true,
							autoplay: { //自动开始
								delay: 2000, //时间间隔
								disableOnInteraction: false, //*手动操作轮播图后不会暂停*
							},
							loop: true, // 循环模式选项
						
						
						});
					},1000)
				}, 1100)
			},
		},

	}
</script>

<style lang="scss" scoped>
	@import url("../assets/swiper-bundle.min.css");

	.tx {
		width: 230px;
		height: 536px;
		overflow: hidden;
		transition: all 1s linear;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.tx-ac {
		width: 700px;
		transition: all 1s linear;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.append-buttons {
		text-align: center;
		margin-top: 20px;
	}

	.append-buttons a {
		display: inline-block;
		border: 1px solid #007aff;
		color: #007aff;
		text-decoration: none;
		padding: 4px 10px;
		border-radius: 4px;
		margin: 0 10px;
		font-size: 13px;
	}

	.donghua {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		z-index: 9;
		cursor: pointer;

		.dh1 {
			left: 65%;
			top: 200px;
		}

		.dh2 {
			top: 80px;
			left: 50%;
			margin-left: -195px;
		}

		.dh3 {
			bottom: 0px;
			left: 0%;
		}

		.dh4 {
			bottom: 0px;
			right: 0%;
		}

		.dh1ac {
			left: 36%;
			top: 450px;
			transition: all 1s linear;
		}

		.dh2ac {
			top: 300px;
			transition: top 1s linear;
		}

		.dh3ac {
			left: -50%;
			transition: left 1s linear;
		}

		.dh4ac {
			right: -50%;
			transition: right 1s linear;
		}
	}

	.men {
		width: 1400px;
		background-color: rgba(255, 255, 255, 0.88);
		border-radius: 10px;
		height: 90px;
		margin: -45px auto;
		position: relative;
		z-index: 3;
		// box-shadow: 0 0 2px 2px #eee;
		display: flex;

		.men-item {
			display: flex;
			align-items: center;
			width: 350px;
			justify-content: center;
			position: relative;
			cursor: pointer;
			border-radius: 10px;
			overflow: hidden;

			span {
				margin-left: 20px;
				font-size: 16px;
			}
		}

		.men-ac {
			border-radius: 10px;
			color: #fff;
			position: relative;

		}

		.bg {
			position: absolute;

			width: 0%;
			height: 100%;
			opacity: 0;
		}

		.bgdh {
			z-index: -1;
			left: 0;
			top: 0;
			width: 100%;
			right: 0;
			bottom: 0;
			background-color: #a5243a;
			opacity: 1;
			transition: width 0.4s linear;
		}

	}

	.item {
		font-size: 16px;
		color: #fff;
		margin-right: 50px;
		position: relative;
		cursor: pointer;
	}

	.itemac {
		color: #B5001F;


	}

	.itemac::after {
		content: '';
		width: 20px;
		height: 4px;
		position: absolute;
		background-color: #B5001F;
		bottom: -28px;
		left: 50%;
		margin-left: -10px;

	}

	.top {
		width: 100%;
		height: 1080px;
		position: relative;

		.top-nav {
			display: flex;
			align-items: center;
			height: 100px;
			width: 1400px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
		}
	}
	.listshow3 {
		width: 100%;
		position: relative;
		.company-about {
			position: absolute;
			width: 1400px;
			padding-left: 125px;
			left:50%;
			top:11%;
			transform: translate(-50%,0);
			.img {
				width: 200px;
			}
			.about {
				width: 525px;
				margin-top: 30px;
				p {
					font-size: 16px;
					line-height: 24px;
					color: #eee;
					text-indent: 2em;
				}
			}
		}
	}
	.flex {
		display: flex;
		justify-content: space-between;
	}
	.company {
		margin: 0 auto;
		width: 1400px;
		margin-top: 60px;
		color: #999;
		font-weight: 700;
		font-size: 14px;
		align-items: center;
		.flex-item {
			padding-left: 78px;
			width: 680px;
			height: 187px;
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
		.production-base {
			background: url('../assets/j1.png') no-repeat center top;
		}
		.contact {
			background: url('../assets/j2.png') no-repeat center top;
		}
		dt {
			font-size: 18px;
			color: #333;
			margin-bottom:10px
		}
	}
</style>